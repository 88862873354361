import logo from './logo.svg';
import React, {useState} from 'react';
import './style.scss';
import {
	HashRouter as Router,
	Switch,
	Route,
	Link,
} from "react-router-dom";
import './App.css';
import Main from './pages/main'

function App() {

  return (
    <div className="App">
			<Router>
				<Switch>
					<Route path="/:lang"><Main/></Route>
				</Switch>
			</Router>
    </div>
  );
}

export default App;
