import React, {useState} from 'react';
import {useParams, Link} from 'react-router-dom'

export default function Main() {
	let {lang} = useParams();
	let [language, setLanguage] = useState(lang);

	return (
		<div>
			<div style={{textAlign:'right', paddingRight:"50px", paddingTop:"10px"}}>
				{ language == "de" ? <Link to="en">English</Link> : <Link to="de">Deutsch</Link> }
			</div>
		</div>
	)
}
